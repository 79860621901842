/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 41번째 로그에서는 Slack 장애, GitHub 스타 어뷰징, Bose NCH 700 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/"
  }, "개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://slack.engineering/rebuilding-slack-on-the-desktop-308d6fe94ae4"
  }, "When a rewrite isn’t: rebuilding Slack on the desktop")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://muchtrans.com/translations/software-disenchantment.ko.html"
  }, "소프트웨어 환멸감")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.rescuetime.com/slack-productivity-outage/"
  }, "Slack vs. Productivity: Here's what happened when Slack went down")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/@changjoopark/%EC%98%A4%ED%94%88%EC%86%8C%EC%8A%A4%EB%A5%BC-%EA%B8%88%EC%A7%80%EB%8B%B9%ED%95%9C-%EC%82%AC%EB%9E%8C%EB%93%A4%EC%9D%98-%EB%AA%B8%EB%B6%80%EB%A6%BC-bd1498652e68"
  }, "오픈소스를 금지당한 사람들의 몸부림 - ChangJoo Park - Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gnu.org/software/repo-criteria.html"
  }, "GNU Ethical Repository Criteria - GNU Project - Free Software Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/1995parham/github-do-not-ban-us/blob/master/README-KO.md"
  }, "github-do-not-ban-us/README-KO.md at master · 1995parham/github-do-not-ban-us")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/"
  }, "Qiita")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/metatron-app/metatron-discovery"
  }, "metatron-app/metatron-discovery: Powerful & Easy way for big data discovery")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/metatron-app/metatron-discovery/issues/2405"
  }, "Stop abuse GitHub Star · Issue #2405 · metatron-app/metatron-discovery")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theregister.co.uk/2019/07/30/would_you_star_a_github_project_for_a_free_drink/"
  }, "Drinks-for-stars promo on open-source GitHub project scrapped after disgusted devs shame it into oblivion • The Register")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/kelseyhightower/nocode"
  }, "kelseyhightower/nocode: The best way to write secure and reliable applications. Write nothing; deploy nowhere.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/sparklemotion/nokogiri"
  }, "sparklemotion/nokogiri: Nokogiri (鋸) is a Rubygem providing HTML, XML, SAX, and Reader parsers with XPath and CSS selector support.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://b2b.tworld.co.kr/bizts/notice/noticeDetail.bs?seqNo=0000000180"
  }, "공지사항(내용보기) < 정보광장 < 고객지원 < 기업 T world")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://imjuno.com/2019/07/29/metatron-discovery-security-review-speed-run/"
  }, "metatron-discovery security review speed run – 임준오의 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://metabase.com/"
  }, "Metabase")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bose.com/en_us/products/headphones/noise_cancelling_headphones/noise-cancelling-headphones-700.html"
  }, "Smart Noise Cancelling Headphones 700 | Bose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bose.com/en_us/products/headphones/earphones/quietcontrol-30.html#v=qc30_black"
  }, "QuietControl 30 (QC30) Wireless Noise Cancelling Earbuds | Bose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bose.com/en_us/products/headphones/over_ear_headphones/quietcomfort-35-wireless-ii.html#v=qc35_ii_rose_gold"
  }, "QuietComfort 35 II Wireless Smart Headphones | Bose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.co.kr/electronics/headband-headphones/wh-1000xm3"
  }, "무선 노이즈 캔슬링 헤드폰| WH-1000XM3 | Sony KR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Anova-Culinary-Precision-Bluetooth-Included/dp/B01HHWSV1S/ref=sr_1_3?keywords=anova&qid=1564981756&s=gateway&sr=8-3"
  }, "Amazon.com: Anova Culinary Sous Vide Precision Cooker | WI-FI + Bluetooth | 900W | Anova App Included: Gateway")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.getawair.com/#/"
  }, "어웨어 세컨드 에디션 - Awair")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/gp/customer-reviews/R12DRIU0Z5QN69/ref=cm_cr_othr_d_rvw_ttl?ie=UTF8&ASIN=B07Q9MJKBV"
  }, "Still prefer the QC35 II's")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.naver.com/PostView.nhn?blogId=spleen99&logNo=220904846801&parentCategoryNo=&categoryNo=179&viewDate=&isShowPopularPosts=false&from=postView"
  }, "대한항공 비즈니스 클래스 노이즈 캔슬링 해드폰 지급 : 네이버 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://sleepbuds.bose.co.kr/"
  }, "Bose noise-masking sleepbuds™")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kickstarter.com/projects/keytron/keychron-k2-a-sleek-compact-wireless-mechanical-ke"
  }, "Keychron K2 - A Sleek, Compact Wireless Mechanical Keyboard by Keychron — Kickstarter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.keychron.com/"
  }, "Keychron | Wireless Mechanical Keyboards for Mac, Windows and Android")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
